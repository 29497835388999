var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HRetribusi'),_c('panel',{staticClass:"panel panel-success",attrs:{"title":"SURAT PEMBERITAHUAN RETRIBUSI DAERAH"}},[_c('b-card',[_c('div',{staticClass:"nav-wizards-container"},[_c('nav',{staticClass:"nav nav-wizards-1 mb-2"},[_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{ 
                                'nav-link': true,
                                active: _vm.active.isActive1,
                                completed: _vm.completed.isCompleted1,
                                disabled: _vm.disabled.isDisabled1
                            },attrs:{"to":{ name: 'StepOne' }}},[_c('div',{staticClass:"nav-no"},[_vm._v("1")]),_c('div',{staticClass:"nav-text"},[_vm._v(" PENDAFTARAN WAJIB RETRIBUSI ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{ 
                                'nav-link': true,
                                active: _vm.active.isActive2,
                                completed: _vm.completed.isCompleted2,
                                disabled: _vm.disabled.isDisabled2
                            },attrs:{"to":{ name: 'StepTwo' }}},[_c('div',{staticClass:"nav-no"},[_vm._v("2")]),_c('div',{staticClass:"nav-text"},[_vm._v(" DETAIL TIKET MASUK ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{ 
                                'nav-link': true,
                                active: _vm.active.isActive3,
                                completed: _vm.completed.isCompleted3,
                                disabled: _vm.disabled.isDisabled3
                            },attrs:{"to":{ name: 'StepThree' }}},[_c('div',{staticClass:"nav-no"},[_vm._v("3")]),_c('div',{staticClass:"nav-text"},[_vm._v(" SELESAI ")])])],1)])])]),_c('div',{staticClass:"card my-2"},[_c('div',{staticClass:"card-body"},[_c('router-view')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }