<template>
    <div>
        <HRetribusi />
        <panel
            title="SURAT PEMBERITAHUAN RETRIBUSI DAERAH"
            class="panel panel-success"
        >
            <b-card>
                <div class="nav-wizards-container">
                    <nav class="nav nav-wizards-1 mb-2">
                        <!-- completed -->
                        <div class="nav-item col">
                            <b-nav-item
                                :class="{ 
                                    'nav-link': true,
                                    active: active.isActive1,
                                    completed: completed.isCompleted1,
                                    disabled: disabled.isDisabled1
                                }"
                                :to="{ name: 'StepOne' }"
                            >
                                <div class="nav-no">1</div>
                                <div class="nav-text">
                                    PENDAFTARAN WAJIB RETRIBUSI
                                </div>
                            </b-nav-item>
                        </div>

                        <!-- active -->
                        <div class="nav-item col">
                            <b-nav-item
                                :class="{ 
                                    'nav-link': true,
                                    active: active.isActive2,
                                    completed: completed.isCompleted2,
                                    disabled: disabled.isDisabled2
                                }"
                                :to="{ name: 'StepTwo' }"
                            >
                                <div class="nav-no">2</div>
                                <div class="nav-text">
                                    DETAIL TIKET MASUK
                                </div>
                            </b-nav-item>
                        </div>
                        <!-- active -->
                        <div class="nav-item col">
                            <b-nav-item
                                :class="{ 
                                    'nav-link': true,
                                    active: active.isActive3,
                                    completed: completed.isCompleted3,
                                    disabled: disabled.isDisabled3
                                }"
                                :to="{ name: 'StepThree' }"
                            >
                                <div class="nav-no">3</div>
                                <div class="nav-text">
                                    SELESAI
                                </div>
                            </b-nav-item>
                        </div>
                    </nav>
                </div>
            </b-card>

            <div class="card my-2">
                <div class="card-body">
                    <router-view />
                </div>
            </div>
        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/npwr/HRetribusi.vue";

export default {
    components: {
        HRetribusi
    },
    data() {
        return {
            active: {
                isActive1: false,
                isActive2: false,
                isActive3: false
            },
            completed: {
                isCompleted1: false,
                isCompleted2: false,
                isCompleted3: false
            },
            disabled: {
                isDisabled1: false,
                isDisabled2: false,
                isDisabled3: false
            }
        };
    },
    created () {
        this.stepClass()
    },
    watch: {
        $route (to, from) {
            this.stepClass()
        }
    },
    methods: {
        stepClass () {
            switch (this.$route.name) {
                case "NextStepOne":
                case "StepOne":
                    // jika step 1 aktif
                    this.active.isActive1 = true;
                    this.active.isActive2 = false;
                    this.active.isActive3 = false;
                    // jika step 1 complete
                    this.completed.isCompleted1 = true;
                    this.completed.isCompleted2 = false;
                    this.completed.isCompleted3 = false;

                    // jika step 1 disable
                    this.disabled.isDisabled1 = false;
                    this.disabled.isDisabled2 = false;
                    this.disabled.isDisabled3 = false;
                    break;
                case "NextStepTwo":   
                case "StepTwo":
                    this.active.isActive1 = false;
                    this.active.isActive2 = true;
                    this.active.isActive3 = false;

                    // jika step 2 complete
                    this.completed.isCompleted1 = true;
                    this.completed.isCompleted2 = false;
                    this.completed.isCompleted3 = false;

                    // jika step 2 disable
                    this.disabled.isDisabled1 = true;
                    this.disabled.isDisabled2 = false;
                    this.disabled.isDisabled3 = false;
                    break;
                case "StepThree":
                    this.active.isActive1 = false;
                    this.active.isActive2 = false;
                    this.active.isActive3 = true;

                    // jika step 2 complete
                    this.completed.isCompleted1 = true;
                    this.completed.isCompleted2 = true;
                    this.completed.isCompleted3 = false;

                    // jika step 2 disable
                    this.disabled.isDisabled1 = true;
                    this.disabled.isDisabled2 = true;
                    this.disabled.isDisabled3 = false;
                    break;
                default:
                    this.active.isActive1 = false;
                    this.active.isActive2 = false;
                    this.active.isActive3 = false;

                    // jika step 1 complete
                    this.completed.isCompleted1 = false;
                    this.completed.isCompleted2 = false;
                    this.completed.isCompleted3 = false;
            }
        }
    }
};
</script>
