<template>
    <div>
        <div class="row pb-3">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <div class="float-right">
                    <h4>
                        <i class="fa fa-info-circle"></i> Step 1 - DATA WAJIB
                        RETRIBUSI
                    </h4>
                </div>
            </div>
        </div>
        <div>
            <b-card class="col-md-12 my-3">
                <b-row>
                    <b-col>
                        <label for="Nomor Pokok"
                            >Cek Berdasarkan Nomor Pokok Wajib Retribusi
                            Daerah:</label
                        >
                        <b-input-group>
                            <b-form-input
                                v-model="no_pokok"
                                placeholder="Masukkan Nomor Pokok"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="info" @click="checkNpwrd(no_pokok, 'pokok')" :disabled="cekNpwrd.isLoading">
                                    <span v-if="cekNpwrd.isLoading">
                                        <b-spinner
                                            variant="light"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                        <span class="text-light">
                                            Memuat...
                                        </span>
                                    </span>
                                    <span v-else>
                                        Cek NPWRD
                                    </span>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger font-weight-bold" v-if="cekNpwrd.dataExist">
                            Data NPWRD Tidak Ditemukan.
                        </small>
                    </b-col>
                    <b-col>
                        <label for="Nomor Induk"
                            >Cek Berdasarkan NIK (Perorangan) atau NIB (Badan
                            Usaha) :</label
                        >
                        <b-input-group>
                            <b-form-input
                                v-model="no_induk"
                                placeholder="Masukkan Nomor Induk"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="warning" @click="checkNpwrd(no_induk, 'induk')">
                                    <span v-if="cekNpwrd.isLoading">
                                        <b-spinner
                                            variant="light"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                        <span class="text-light">
                                            Memuat...
                                        </span>
                                    </span>
                                    <span v-else>
                                        Cek NPWRD
                                    </span>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger font-weight-bold" v-if="cekNpwrd.dataExist2">
                            Data NPWRD Tidak Ditemukan.
                        </small>
                    </b-col>
                </b-row>
            </b-card>
            <b-card>
                <div class="nav-wizards-container">
                    <nav class="nav nav-wizards-2">
                        <!-- completed -->
                        <div class="nav-item col">
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: isActive,
                                }"
                                style="cursor: pointer;"
                                @click="tabButton(true)"
                            >
                                <div class="nav-text">
                                    IDENTITAS WAJIB RETRIBUSI DAERAH
                                </div>
                            </a>
                        </div>

                        <!-- active -->
                        <div class="nav-item col">
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: isActive2
                                }"
                                style="cursor: pointer;"
                                @click="tabButton(false)"
                            >
                                <div class="nav-text">
                                    PENANGGUNG JAWAB
                                </div>
                            </a>
                        </div>
                    </nav>
                </div>
            </b-card>
            <b-card class="my-3">
                <div
                    v-if="cekNpwrd.isLoading || cekNpwrd.isLoading2"
                    class="text-center"
                    style="height: 100vh; margin-top: 20%"
                >
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <div class="text-primary">
                        Memuat...
                    </div>
                </div>
                <div>
                    <div class="card-body" v-if="wajib">
                        <WRIndividu />
                    </div>
                    <div class="card-body" v-else>
                        <WRBadanUsaha />
                    </div>
                </div>
                <div>
                    <b-button variant="primary" @click="store" :disabled="buttonDisabled">
                        <b-spinner variant="primary" small label="Spinning" v-if="isLoading"></b-spinner>
                        <i class="fa fa-save" v-else></i> Simpan
                    </b-button>
                </div>
            </b-card>
            <div class="my-3">
                <span class="float-right">
                    <div>
                        <b-button
                            @click="nextButton"
                            variant="success"
                            :disabled="isNext"
                        >
                            <i class="fa fa-arrow-circle-right"></i>
                            Selanjutnya
                        </b-button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import WRIndividu from "@/components/npwr/WRIndividu.vue";
import WRBadanUsaha from "@/components/npwr/WRBadanUsaha.vue";
import { mapState } from "vuex";
import detailSptMixin from "@/helper/api/sptDetail.js";

export default {
    mixins: [detailSptMixin],
    components: {
        WRIndividu,
        WRBadanUsaha
    },
    data () {
        return {
            isActive: true,
            isActive2: false,
            wajib: true,
            no_pokok: JSON.parse(localStorage.getItem("no_pokok")),
            no_induk: JSON.parse(localStorage.getItem("no_induk")),
            isLoading: false,
            editMode: false,
            form: new Form({
                masa: '0',
                tahun: '0',
                npwrd_id: ''
            }),
            sptId: null,
            isNext: true,
            buttonDisabled: false
        }
    },
    computed: {
        ...mapState(["BentukBadan", "Jabatan", "cekNpwrd", "Pendaftaran"])
    },
    mounted () {
        this.$store.dispatch("WilayahIndonesia/getProvinsi");
        if (this.$route.name == 'NextStepOne') {
            this.dataSpt(this.$route.params.id)
            this.isNext = false
            this.buttonDisabled = true
        } else {
            if (this.cekNpwrd.prevMode || JSON.parse(localStorage.getItem("npwrdId")) !== null) {
                this.isNext = false
                this.buttonDisabled = true
                if (this.no_pokok) {
                    this.checkNpwrd(this.no_pokok, "pokok");
                } else if (this.no_induk) {
                    this.checkNpwrd(this.no_induk, "induk");
                }
                this.sptId = JSON.parse(localStorage.getItem('sptId'))
            } else {
                this.isNext = true
                this.buttonDisabled = false
                if (this.cekNpwrd.dataNpwrd.length === 0) {
                    this.no_pokok = "";
                    this.no_induk = "";
                }
            }
        }
        
    },
    methods: {
        // tambah data
        store() {
            this.isLoading = true;
            this.form.npwrd_id = this.cekNpwrd.detailNpwrd.id;
            this.form
                .post("/api/transaksi/spt/tiket-masuk")
                .then((response) => {
                    if (this.form.successful) {
                        this.isLoading = false
                        this.isNext = false
                        this.buttonDisabled = true
                        this.sptId = response.data.data.id;
                        this.$swal({
                            title:
                                "MASA RETRIBUSI Berhasil Dijalankan",
                            text: 'Silahkan tekan "Selanjutnya"',
                            icon: "success",
                            showCloseButton: true,
                            confirmButtonText:
                                'Selanjutnya <i class="fa fa-arrow-circle-right"></i>',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.nextButton();
                            }
                        });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.buttonDisabled = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.$swal({
                            title: 'Anda Belum Cek Data Npwrd',
                            icon: 'warning',
                            showCloseButton: true,
                            confirmButtonText: 'OKE',
                            timer: 5000
                        })
                        this.isNext = true
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        tabButton(bol) {
            if (bol) {
                this.wajib = bol;
                this.isActive2 = false;
            } else {
                this.wajib = bol;
                this.isActive2 = true;
            }
        },
        nextButton () {
            localStorage.setItem("npwrdId", this.cekNpwrd.detailNpwrd.id);
            if (this.$route.name == 'NextStepOne') {
                console.log('test')
                this.$router.push({ name: 'NextStepTwo', params: { id: this.$route.params.id }})
                localStorage.setItem("sptId", this.sptId);
            } else {
                this.$router.push({ name: 'StepTwo' })
                localStorage.setItem("sptId", this.sptId);
                if (this.no_pokok) {
                    localStorage.setItem("no_pokok", this.no_pokok);
                } else if (this.no_induk) {
                    localStorage.setItem("no_induk", this.no_induk);
                }
            }
        },
        // cek nomor pokok
        checkNpwrd(nomor, value) {
            if (value == "pokok") {
                this.$store.dispatch("cekNpwrd/detail", nomor);
            } else if (value == "induk") {
                this.$store.dispatch("cekNpwrd/detailInduk", nomor);
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("cekNpwrd/dataNpwrd", []);
        this.$store.commit("cekNpwrd/existNpwrd", {
            bol: false,
            name: "pokok"
        });
        this.$store.commit("cekNpwrd/existNpwrd", {
            bol: false,
            name: "induk"
        });
        this.$store.commit("cekNpwrd/isNext", true);
        localStorage.setItem("no_pokok", null);
        localStorage.setItem("no_induk", null);
        next();
    },
    beforeRouteEnter(to, from, next) {
        localStorage.setItem("no_pokok", null);
        localStorage.setItem("no_induk", null);
        localStorage.setItem("npwrdId", null);
        localStorage.setItem("sptId", null)
        next();
    }
}
</script>
