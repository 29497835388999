<template>
	<div>
		<!-- Panel -->
        <div class="text-center my-3">
            <h4 class="mb-1 font-weight-bold">
                SKRD &amp; RINCIAN BIAYA RETRIBUSI
            </h4>
            <p>
                Pendataan transaksi retribusi telah selesai dibuat. <br>
                Untuk melihat dokumen hasil pendataan transaksi retribusi, silahkan klik tombol di bawah ini.
            </p>
        </div>
        <div class="my-5 row">
            <!-- <div class="col-lg-1"></div> -->
            <!-- begin col-4 -->
            <div class="col-lg-5" @click="showPdf(id, 'skr')">
                <div class="widget widget-stats bg-gradient-teal m-b-10 custom-hover">
                    <div class="stats-icon stats-icon-lg"><i class="fa fa-file-pdf fa-fw"></i></div>
                    <div class="stats-content">
                        <div class="stats-title">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</div>
                        <div class="stats-number" style="height: 5rem">
                            SKRD
                        </div>
                        <div class="stats-desc">
                            Dokumen Surat Ketetapan Retribusi Daerah
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col-4 -->
            <!-- begin col-4 -->
            <!-- <div class="col-lg-4" @click="showPdf(id, 'kab./kota')">
                <div class="widget widget-stats bg-gradient-blue m-b-10 custom-hover">
                    <div class="stats-icon stats-icon-lg"><i class="fa fa-file-pdf fa-fw"></i></div>
                    <div class="stats-content">
                        <div class="stats-title">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</div>
                        <div class="stats-number" style="height: 6rem">
                            DOKUMEN SPTPD BERDASARKAN KAB./KOTA
                        </div>
                        <div class="stats-desc"> 
                            Rincian Dokumen Surat Pemberitahuan Pajak Daerah (SPTPD) Berdasarkan Kab./Kota
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- end col-4 -->
            <!-- begin col-4 -->
            <div class="col-lg-5" @click="showPdf(id, 'spt')">
                <div class="widget widget-stats bg-gradient-purple m-b-10 custom-hover">
                    <div class="stats-icon stats-icon-lg"><i class="fa fa-file-pdf fa-fw"></i></div>
                    <div class="stats-content">
                        <div class="stats-title">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</div>
                        <div class="stats-number" style="height: 5rem">
                            RINCIAN BIAYA RETRIBUSI
                        </div>
                        <div class="stats-desc">
                            Dokumen Rincian Biaya Retribusi
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-1"></div> -->
            <!-- end col-4 -->
        </div>
        <div class="text-center mb-3">
            <h4 class="mb-1 font-weight-bold">
                TERIMA KASIH
            </h4>
            <p>
                <!-- <strong>Terima kasih </strong> -->
                Telah menjadi bagian dalam pembangunan Provinsi Sumatera Utara.<br>
                Kami bangga bekerja sama dengan anda. Teruslah bekerja sama untuk masa depan yang lebih baik.
            </p>
        </div>

        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from '@/assets/images/logoSumut.png'
import PageOptions from '@/config/PageOptions.vue'
import axios from 'axios'
import hapusStorage from '@/helper/hapusLocalStore'
import dateMixin from '@/helper/dateFormat.js'

export default {
    mixins: [dateMixin, hapusStorage],
	data () {
		return {
			imageLogo: Logo,
			role: localStorage.getItem('roles'),
            id: JSON.parse(localStorage.getItem('sptId')),
            spt: [],
            // bulan
            bulanOptions: [
                {
                    nama: "Januari",
                    id: "01"
                },
                {
                    nama: "Februari",
                    id: "02"
                },
                {
                    nama: "Maret",
                    id: "03"
                },
                {
                    nama: "April",
                    id: "04"
                },
                {
                    nama: "Mei",
                    id: "05"
                },
                {
                    nama: "Juni",
                    id: "06"
                },
                {
                    nama: "Juli",
                    id: "07"
                },
                {
                    nama: "Agustus",
                    id: "08"
                },
                {
                    nama: "September",
                    id: "09"
                },
                {
                    nama: "Oktober",
                    id: "10"
                },
                {
                    nama: "November",
                    id: "11"
                },
                {
                    nama: "Desember",
                    id: "12"
                }
            ],
            masa_pajak: '',
            rek7: [],
            total_volume: 0,
            spt_kabkota: [],
            rek7Arr: null,
            volume: JSON.parse(localStorage.getItem('volume')),
            dpp: JSON.parse(localStorage.getItem('dpp')),
            pbbkb: JSON.parse(localStorage.getItem('pbbkb')),
            total_dpp: 0,
            total_pbbkb: 0,
            tgl_sekarang: null,
            kabkota: [],
            npwpd_arr: '',
            pdfUrl: '',
            showLoader: false,
            isLoading: false,
            namaDokumen: ''
		}
	},
	created() {
		PageOptions.pageEmpty = false;
		document.body.className = '';
	},
    mounted () {
        // this.getNpwpd()
        // this.getSpt()
        // this.getRekening7()
        // this.getSptKabKota()
        // this.getDate()
    },
    methods: {
        showPdf (id, cond) {
			switch (cond) {
                case "skr":
                    this.getPdf(id)
                    this.namaDokumen = "Detail SKRD Provinsi"
                    break
                case "spt":
                    this.getPdfRincian(id)
                    this.namaDokumen = "Detail SPTRD Provinsi"
                    break
                default: 
                    this.pdfUrl = ''
            }
        },

        // // view pdf
        getPdf (id) {
            this.showLoader = true
            axios.get('/api/view-pdf/surat/skr/spt-' + id, {responseType: 'arraybuffer', timeout: 10000}).then(response => {
                this.showLoader = false
                this.pdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                window.open(this.pdfUrl, '_blank')
            }).catch(error => {
                this.showLoader = false
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },

        getPdfRincian (id) {
            this.showLoader = true
            axios.get('/api/view-pdf/surat/spt/detail/spt-' + id, {responseType: 'arraybuffer'}).then(response => {
                this.showLoader = false
                this.pdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                window.open(this.pdfUrl, '_blank')
            }).catch(error => {
                this.showLoader = false
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },
        // showSptp () {
        //     if (this.show === true) {
        //         this.show = false
        //         this.judulButton = "SPTP"
        //         this.idPdf = 'sptp'
        //     } else {
        //         this.judulButton = "SSPP"
        //         this.idPdf = 'sspp'
        //         this.show = true
        //     }
        // },
    },
}
</script>

<style scoped>
.custom-hover:hover {
    /* box-shadow: 5px 5px rgba(68, 67, 67, 0.281); */
    cursor: pointer;
    transform: scale(1.05);
    transition: all ease 0.4s;
}
</style>